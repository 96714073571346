<template>
  <div
    :style="{ 'border-left': 'solid 5px' + taskBorderColor }"
    class="ant-glass-background card-container"
  >
    <div v-if="node.job.status === 'planned'" class="disabled-overlay" />
    <div class="card-header">
      <div class="card-title">
        {{ node.block.name }}
      </div>
      <task-status
        v-if="node.job.task"
        :status="node.job.task.status"
        tooltip="left"
        @openTaskDetail="openTaskDetail"
      />
    </div>
    <div class="card-description">
      {{ node.block.description }}
    </div>
    <div class="card-footer">
      <div v-if="node.job.task" class="card-assigned_user">
        {{
          node.job.task.assigned_to.firstname +
          ' ' +
          node.job.task.assigned_to.lastname
        }}
      </div>
      <v-btn
        v-if="node.job.status !== 'planned'"
        class="card-btn"
        color="primary"
        depressed
        small
        @click="doCardAction()"
      >
        {{ cardBtnText }}
      </v-btn>
    </div>

    <assign-job-dialog
      v-if="assignDialog"
      :job-dialog="assignDialog"
      :job-item="node.job"
      @closeAssignJob="assignDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TaskHelper from '../../../services/task-helper';
import TaskStatus from '../../Tasks/TaskStatus';
import AssignJobDialog from '@/components/Project/Workflows/AssignJobDialog';

export default {
  name: 'DiagramCard',
  components: { AssignJobDialog, TaskStatus },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      assignDialog: false,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser']),
    cardBtnText() {
      switch (this.node.job.status) {
        case 'open':
          return 'Assign';
        case 'assigned':
          return 'Start';
        case 'planned':
          return '';
        case 'completed':
          return 'Result';
        default:
          return '';
      }
    },
    taskBorderColor() {
      if (this.node.job.task !== null) {
        return TaskHelper.getStatusIconColor(this.node.job.task.status);
      } else {
        return '#616161';
      }
    },
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      if (this.node.links !== null) {
        let element = document.getElementById(this.node.id);
        this.node.links.forEach((link) => {
          let previousElement = document.getElementById(link.node);

          let lineConfig = {
            id: link.id,
            x1: element.offsetLeft + element.offsetWidth / 2 + 115,
            y1: element.offsetTop + element.offsetHeight / 2,
            x2:
              previousElement.offsetLeft +
              previousElement.offsetWidth / 2 -
              115 -
              8,
            y2: previousElement.offsetTop + previousElement.offsetHeight / 2,
          };

          this.$emit('drawLine', lineConfig);
        });
      }
    },
    openTaskDetail() {
      this.$router.push({
        name: 'tasks-detail',
        params: { taskId: this.node.job.task.id },
      });
    },
    doCardAction() {
      switch (this.node.job.status) {
        case 'open':
          this.assignDialog = true;
          break;
        case 'assigned':
          this.openWorkflowNode(this.node);
          break;
        case 'completed':
          this.$store.dispatch('setNode', this.node);
          this.$router.push({
            name: 'project-workflow-session-node',
            params: {
              workflowId: this.$route.params.workflowId,
              sessionId: this.$route.params.sessionId,
              nodeId: this.node.id,
            },
          });
          break;
        default:
          return '';
      }
    },
    openWorkflowNode(node) {
      if (node.job.status === 'assigned') {
        if (node.job.task.assigned_to.id === this.authenticatedUser.id) {
          this.$store.dispatch('setNode', node);
          this.$router.push({
            name: 'project-workflow-session-node',
            params: {
              workflowId: this.$route.params.workflowId,
              sessionId: this.$route.params.sessionId,
              nodeId: node.id,
            },
          });
        } else {
          this.$store.commit('showNotification', {
            content: 'Only the assigned user can pending this queue',
            color: 'error',
          });
        }
      } else {
        this.$store.commit('showNotification', {
          content: 'Assign queue first',
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  width: 230px;
  min-height: 120px;
  padding: 10px 12px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  .disabled-overlay {
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: none !important;
  }

  .card-header {
    display: flex;
    overflow-wrap: anywhere;

    .card-title {
      font-size: 14px;
      font-weight: 500;
      flex: 1;
    }
  }

  .card-description {
    font-size: 12px;
    flex: 1;
  }

  .card-footer {
    display: flex;
    align-items: center;

    .card-assigned_user {
      font-size: 11px;
      flex: 1;
    }

    .card-btn {
      font-size: 11px;
    }
  }
}
</style>
