<template>
  <div>
    <dynamic-data-table
      :can-delete="true"
      :is-loading="projectWorkflowRolesStatus === 'loading'"
      :table-headers="roleHeaders"
      :table-records="projectWorkflowRoles"
      table-title="Roles"
      @deleteItem="setupDelete"
    >
      <template #item.name="{ value, rowId, item }">
        <v-edit-dialog
          :return-value.sync="value"
          @close="workflowRoleNames[rowId] = undefined"
          @open="workflowRoleNames[rowId] = value"
          @save="updateProjectWorkflowRoleName(item)"
        >
          {{ value }}
          <template #input>
            <v-text-field
              v-model="workflowRoleNames[rowId]"
              :rules="[rules.maxLength]"
              counter
              label="Edit"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>
      <template #item.color="{ value, rowId, item }">
        <v-edit-dialog
          :return-value.sync="value"
          large
          @close="workflowRoleColors[rowId] = undefined"
          @open="workflowRoleColors[rowId] = value"
          @save="updateProjectWorkflowRoleColor(item)"
        >
          <div
            :style="{ 'background-color': value }"
            style="
              border-radius: 50%;
              width: 25px;
              height: 25px;
              margin-top: 4px;
            "
          />
          <template #input>
            <v-color-picker
              v-model="workflowRoleColors[rowId]"
              dot-size="15"
              hide-inputs
            />
          </template>
        </v-edit-dialog>
      </template>
      <template #table-actions>
        <div v-if="selectedRole" class="table-filter-container">
          <span>Selected: </span>
          <v-chip
            :color="selectedRole.color"
            :text-color="getContrastColor(selectedRole.color)"
            class="ma-2"
            close
            @click:close="removeSelectedRole()"
          >
            {{ selectedRole.name }}
          </v-chip>
        </div>
        <div v-if="filteredRole" class="table-filter-container">
          <span>Filter: </span>
          <v-chip
            :color="filteredRole.color"
            :text-color="getContrastColor(filteredRole.color)"
            class="ma-2"
            close
            @click:close="removeNodesAndTeamsFilter()"
          >
            {{ filteredRole.name }}
          </v-chip>
        </div>
        <v-dialog
          key="add-project-role"
          v-model="workflowRoleDialog"
          max-width="500px"
          @click:outside="closeWorkflowRoleDialogs"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              v-bind="attrs"
              @click="workflowRoleDialog = true"
              v-on="on"
            >
              New Role
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline"> Add Role to project workflow </span>
            </v-card-title>
            <v-form ref="form" @submit.prevent>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="6">
                      <v-text-field
                        v-model="workflowRoleItem.name"
                        :rules="[rules.required, rules.maxLength]"
                        counter="250"
                        label="name"
                        maxlength="250"
                      />
                    </v-col>
                    <v-col style="display: flex; justify-content: center">
                      <v-color-picker
                        v-model="workflowRoleItem.color"
                        dot-size="15"
                        hide-inputs
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>

            <v-card-actions>
              <v-spacer />
              <v-btn color="error" text @click="closeWorkflowRoleDialogs">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="addRoleToWorkflowProject">
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template #table-row-actions="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              style="margin-right: 10px"
              v-bind="attrs"
              @click="selectRole(item)"
              v-on="on"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>Add to team or node</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              small
              style="margin-right: 10px"
              v-bind="attrs"
              @click="filterNodesAndTeams(item)"
              v-on="on"
            >
              mdi-filter
            </v-icon>
          </template>
          <span>filter teams and nodes</span>
        </v-tooltip>
      </template>
    </dynamic-data-table>
    <delete-dialog
      :dialog="workflowRoleDeleteDialog"
      :title="`Are you sure you want to remove ${workflowRoleItem.name}?`"
      @closeDialog="closeWorkflowRoleDialogs"
      @deleteAction="deleteProjectWorkflowRole"
    />
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog';
import ColorHelper from '@/services/task-helper';

export default {
  name: 'WorkflowManagementRoles',
  components: { DeleteDialog, DynamicDataTable },
  data: () => {
    return {
      roleHeaders: [
        {
          text: 'name',
          value: 'name',
          hasSlot: true,
        },
        {
          text: 'color',
          value: 'color',
          hasSlot: true,
        },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },
      ],
      workflowRoleNames: [],
      workflowRoleColors: [],

      workflowRoleDialog: false,
      workflowRoleDeleteDialog: false,
      workflowRoleItem: {},
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          (value && value.length < 250) || 'Max 250 characters',
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'projectWorkflowRoles',
      'projectWorkflowRolesStatus',
      'selectedRole',
      'filteredRole',
    ]),
  },
  mounted() {
    this.$store.dispatch('fetchProjectWorkflowRoles', {
      projectId: this.project.id,
      workflowId: this.$route.params.workflowId,
    });
  },
  methods: {
    closeWorkflowRoleDialogs() {
      this.workflowRoleItem = Object.assign({}, {});
      this.workflowRoleDialog = false;
      this.workflowRoleDeleteDialog = false;
    },
    setupDelete(item) {
      this.workflowRoleItem = item;
      this.workflowRoleDeleteDialog = true;
    },
    addRoleToWorkflowProject() {
      if (this.$refs.form.validate()) {
        // create body
        let body = {
          name: this.workflowRoleItem.name,
          color: this.workflowRoleItem.color.hex,
        };

        // create task
        this.$store
          .dispatch('addRoleToWorkflowProject', {
            projectId: this.project.id,
            workflowId: this.$route.params.workflowId,
            body,
          })
          .then((response) => {
            this.closeWorkflowRoleDialogs();
          });
      }
    },
    updateProjectWorkflowRoleName(item) {
      let body = {
        name: this.workflowRoleNames[item.id],
      };

      this.$store.dispatch('updateProjectWorkflowRole', {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        roleId: item.id,
        body,
      });
    },
    updateProjectWorkflowRoleColor(item) {
      // create body
      let body = {
        color: this.workflowRoleColors[item.id],
      };

      // create task
      this.$store.dispatch('updateProjectWorkflowRole', {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        roleId: item.id,
        body,
      });
    },
    deleteProjectWorkflowRole() {
      this.$store
        .dispatch('deleteProjectWorkflowRole', {
          projectId: this.project.id,
          workflowId: this.$route.params.workflowId,
          roleId: this.workflowRoleItem.id,
        })
        .then(() => {
          this.closeWorkflowRoleDialogs();
        });
    },
    selectRole(value) {
      this.$store.dispatch('selectRole', { role: value });
    },
    removeSelectedRole() {
      this.$store.dispatch('removeSelectedRole');
    },
    filterNodesAndTeams(value) {
      this.$store.dispatch('filterRole', { role: value });
    },
    removeNodesAndTeamsFilter() {
      this.$store.dispatch('removeFilteredRole');
    },
    getContrastColor(colorHex) {
      return ColorHelper.getContrastColor(colorHex);
    },
  },
};
</script>

<style scoped></style>
