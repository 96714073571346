<template>
  <div
    v-if="projectWorkflowStatus === 'success'"
    class="d-flex flex-column flex-1"
  >
    <div
      class="ant-glass-background ant-header ant-border-bottom radius-0 pos-rel d-flex align-center mb-0"
    >
      <v-btn
        :ripple="false"
        class="elevation-0 mr-2"
        color="transparent"
        small
        @click="navigateToProjectWorkflows"
      >
        <v-icon left> mdi-chevron-left</v-icon>
        workflows
      </v-btn>
      <div style="flex: 1; font-size: 18px">
        <v-edit-dialog
          v-if="$can('update', 'project-workflow')"
          :return-value.sync="projectWorkflow.name"
          large
          @close="projectWorkflowName = undefined"
          @open="projectWorkflowName = projectWorkflow.name"
          @save="updateProjectWorkflowName()"
        >
          {{ projectWorkflow.name }}
          <template #input>
            <v-text-field
              v-model="projectWorkflowName"
              :rules="[rules.maxLength]"
              counter
              label="Edit"
              single-line
            />
          </template>
        </v-edit-dialog>
        <div v-else>
          {{ projectWorkflow.name }}
        </div>
      </div>
      <v-btn
        v-if="$can('delete', 'project-workflow')"
        class="mr-2"
        icon
        @click="projectWorkflowDeleteDialog = true"
      >
        <v-icon dense> mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="
          projectWorkflowPermissions['start-session'] ||
          $can('start', 'project-workflow-session')
        "
        :disabled="getNonConfiguredNodes.length > 0"
        color="primary"
        small
        @click="sessionDialog = true"
      >
        Start Session
      </v-btn>
    </div>

    <workflow-session-dialog
      :session-dialog="sessionDialog"
      @closeDialog="sessionDialog = false"
    />

    <delete-dialog
      :dialog="projectWorkflowDeleteDialog"
      :title="`Are you sure you want to remove ${projectWorkflow.name}?`"
      description="This removes all sessions and tasks linked to this workflow"
      @closeDialog="projectWorkflowDeleteDialog = false"
      @deleteAction="deleteProjectWorkflow"
    />

    <div class="d-flex flex-1 overflow-y-auto">
      <workflow-management-roles
        class="ant-glass-background radius-0 ant-border-right"
      />
      <div class="d-flex flex-column flex-1">
        <workflow-management-nodes
          class="ant-glass-background radius-0 flex-1 ant-border-bottom"
        />
        <workflow-management-teams
          class="ant-glass-background radius-0 flex-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WorkflowManagementRoles from '@/components/Project/Workflows/Management/WorkflowManagementRoles';
import WorkflowManagementNodes from '@/components/Project/Workflows/Management/WorkflowManagementNodes';
import WorkflowManagementTeams from '@/components/Project/Workflows/Management/WorkflowManagementTeams';
import DeleteDialog from '@/components/DeleteDialog';
import WorkflowSessionDialog from '@/components/Dialogs/WorkflowSessionDialog';

const name = 'WorkflowManagement';

export default {
  name: name,
  components: {
    WorkflowSessionDialog,
    DeleteDialog,
    WorkflowManagementTeams,
    WorkflowManagementNodes,
    WorkflowManagementRoles,
  },
  data: () => {
    return {
      projectWorkflowName: undefined,
      projectWorkflowDeleteDialog: false,
      sessionDialog: false,
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          (value && value.length < 250) || 'Max 250 characters',
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'projectWorkflow',
      'projectWorkflowStatus',
      'projectWorkflowNodes',
      'projectWorkflowPermissions',
    ]),
    getNonConfiguredNodes() {
      return this.projectWorkflowNodes.filter(
        (item) => item.roles.length === 0
      );
    },
  },
  mounted() {
    this.$store.dispatch('fetchProjectWorkflow', {
      projectId: this.project.id,
      workflowId: this.$route.params.workflowId,
    });
  },
  methods: {
    updateProjectWorkflowName() {
      // create body
      let body = {
        name: this.projectWorkflowName,
      };

      // update workflow name
      this.$store.dispatch('updateProjectWorkflow', {
        projectId: this.project.id,
        workflowId: this.projectWorkflow.id,
        body,
      });
    },
    deleteProjectWorkflow() {
      this.$store
        .dispatch('deleteProjectWorkflow', {
          projectId: this.projectWorkflow.project.id,
          workflowId: this.projectWorkflow.id,
        })
        .then(() => {
          this.$router.push({ name: 'project-workflows' });
        });
    },
    navigateToProjectWorkflows() {
      this.$router.push({ name: 'project-workflows' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
