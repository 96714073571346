<template>
  <dynamic-data-table
    :is-loading="projectWorkflowNodesStatus === 'loading'"
    :table-headers="nodeHeaders"
    :table-records="filteredNodes"
    table-title="Blocks"
  >
    <template #item.block="{ value }">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ value.name }}
          </span>
        </template>
        <span>{{ value.description }}</span>
      </v-tooltip>
    </template>
    <template #item.roles="{ value, rowId, item }">
      <v-chip
        v-for="role in value"
        :key="role.id"
        :color="role.color"
        :text-color="getContrastColor(role.color)"
        class="ma-2"
        close
        @click:close="removeRoleFromNode(rowId, role)"
      >
        {{ role.name }}
      </v-chip>
    </template>
    <template #table-actions>
      <span v-if="getNonConfiguredNodes.length > 0">
        {{ getNonConfiguredNodes.length }} nodes do not have a role
        assigned</span
      >
    </template>
    <template #table-row-actions="{ item }">
      <v-tooltip
        v-if="
          selectedRole !== null &&
          item.roles.findIndex((x) => x.id === selectedRole.id) === -1
        "
        left
      >
        <template #activator="{ on, attrs }">
          <v-icon
            :disabled="selectedRole === null"
            small
            v-bind="attrs"
            @click="addRoleToNode(item)"
            v-on="on"
          >
            mdi-account-key
          </v-icon>
        </template>
        <span>Add "{{ selectedRole.name }}" to team</span>
      </v-tooltip>
    </template>
  </dynamic-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import ColorHelper from '@/services/task-helper';

export default {
  name: 'WorkflowManagementNodes',
  components: { DynamicDataTable },
  data: () => ({
    nodeHeaders: [
      {
        text: 'name',
        value: 'block',
        hasSlot: true,
      },
      {
        text: 'roles',
        value: 'roles',
        hasSlot: true,
      },
      { text: 'Actions', value: 'actions', align: 'right', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters([
      'project',
      'projectWorkflowNodes',
      'projectWorkflowNodesStatus',
      'filteredRole',
      'selectedRole',
    ]),

    filteredNodes() {
      if (this.filteredRole) {
        return this.projectWorkflowNodes.filter(
          (node) =>
            node.roles.findIndex((role) => role.id === this.filteredRole.id) !==
            -1
        );
      }
      return this.projectWorkflowNodes;
    },
    getNonConfiguredNodes() {
      return this.projectWorkflowNodes.filter(
        (item) => item.roles.length === 0
      );
    },
  },
  mounted() {
    this.$store.dispatch('fetchProjectWorkflowNodes', {
      projectId: this.project.id,
      workflowId: this.$route.params.workflowId,
    });
  },
  methods: {
    addRoleToNode(node) {
      this.$store.dispatch('addRoleToNode', {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        role: this.selectedRole,
        node: node,
      });
    },
    removeRoleFromNode(nodeId, role) {
      this.$store.dispatch('removeRoleFromNode', {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        role: role,
        node: nodeId,
      });
    },
    getContrastColor(colorHex) {
      return ColorHelper.getContrastColor(colorHex);
    },
  },
};
</script>

<style scoped></style>
