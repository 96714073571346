<template>
  <markdown-renderer :source="data" />
</template>

<script>
import MarkdownRenderer from '@/components/MarkdownRenderer';

export default {
  name: 'MarkdownPanel',
  components: { MarkdownRenderer },
  props: {
    markdown: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      data: undefined,
    };
  },
  mounted() {
    let test = this.urlB64ToUint8Array(this.markdown.data);
    let decoder = new TextDecoder('utf-8');
    this.data = decoder.decode(test);
  },
  methods: {
    urlB64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }

      return outputArray;
    },
  },
};
</script>
