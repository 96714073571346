<template>
  <div>
    <v-form ref="form">
      <v-row class="flex-wrap d-flex">
        <v-col
          v-for="field in section.fields"
          :key="field.id"
          class="lg-4"
          cols="4"
        >
          <div v-if="field.required">
            <v-text-field
              v-if="field.type === 'text'"
              v-model="inputItem[field.column]"
              :disabled="node.job.status === 'completed' || isLoading"
              :label="field.label"
              :placeholder="field.placeholder"
              :rules="[formRules.required]"
              :suffix="field.suffix"
              class="inputfield"
            >
              <template v-if="field.tooltip" #prepend>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline</v-icon>
                  </template>
                  {{ field.tooltip }}
                </v-tooltip>
              </template>
            </v-text-field>
            <v-text-field
              v-if="field.type === 'number'"
              v-model="inputItem[field.column]"
              :disabled="node.job.status === 'completed' || isLoading"
              :label="field.label"
              :max="parseInt(field.max)"
              :min="parseInt(field.min)"
              :placeholder="field.placeholder"
              :rules="[
                formRules.required,
                formRules.inRange(
                  field.min,
                  field.max,
                  inputItem[field.column]
                ),
              ]"
              :suffix="field.suffix"
              class="inputfield"
              type="number"
            >
              <template v-if="field.tooltip" #prepend>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline</v-icon>
                  </template>
                  {{ field.tooltip }}
                </v-tooltip>
              </template>
            </v-text-field>
            <v-select
              v-if="field.type === 'select'"
              v-model="inputItem[field.column]"
              :disabled="node.job.status === 'completed' || isLoading"
              :items="field.items"
              :label="field.label"
              :placeholder="field.placeholder"
              :rules="[formRules.required]"
              :suffix="field.suffix"
              class="inputfield"
            >
              <template v-if="field.tooltip" #prepend>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline</v-icon>
                  </template>
                  {{ field.tooltip }}
                </v-tooltip>
              </template>
            </v-select>
            <v-file-input
              v-else-if="field.type === 'document'"
              v-model="inputItem[field.column]"
              :disabled="node.job.status === 'completed' || isLoading"
              :label="field.label"
              :rules="[formRules.required]"
            >
              <template v-if="field.tooltip" #prepend>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline</v-icon>
                  </template>
                  {{ field.tooltip }}
                </v-tooltip>
              </template>
            </v-file-input>
          </div>
          <div v-else>
            <v-text-field
              v-if="field.type === 'text'"
              v-model="inputItem[field.column]"
              :disabled="node.job.status === 'completed' || isLoading"
              :label="field.label"
              :placeholder="field.placeholder"
              :suffix="field.suffix"
              class="inputfield"
            >
              <template v-if="field.tooltip" #prepend>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline</v-icon>
                  </template>
                  {{ field.tooltip }}
                </v-tooltip>
              </template>
            </v-text-field>
            <v-text-field
              v-if="field.type === 'number'"
              v-model="inputItem[field.column]"
              :disabled="node.job.status === 'completed' || isLoading"
              :label="field.label"
              :max="parseInt(field.max)"
              :min="parseInt(field.min)"
              :placeholder="field.placeholder"
              :rules="[
                formRules.inRange(
                  field.min,
                  field.max,
                  inputItem[field.column]
                ),
              ]"
              :suffix="field.suffix"
              class="inputfield"
              type="number"
            >
              <template v-if="field.tooltip" #prepend>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline</v-icon>
                  </template>
                  {{ field.tooltip }}
                </v-tooltip>
              </template>
            </v-text-field>
            <v-select
              v-if="field.type === 'select'"
              v-model="inputItem[field.column]"
              :disabled="node.job.status === 'completed' || isLoading"
              :items="field.items"
              :label="field.label"
              :placeholder="field.placeholder"
              :suffix="field.suffix"
              class="inputfield"
            >
              <template v-if="field.tooltip" #prepend>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline</v-icon>
                  </template>
                  {{ field.tooltip }}
                </v-tooltip>
              </template>
            </v-select>
            <v-file-input
              v-else-if="field.type === 'document'"
              v-model="inputItem[field.column]"
              :disabled="node.job.status === 'completed' || isLoading"
              :label="field.label"
            >
              <template v-if="field.tooltip" #prepend>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon v-on="on"> mdi-information-outline</v-icon>
                  </template>
                  {{ field.tooltip }}
                </v-tooltip>
              </template>
            </v-file-input>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-btn
      v-if="section.type === 'multi-input' && node.job.status === 'assigned'"
      block
      color="primary"
      cols="4"
      @click="addInputToTable()"
    >
      Add
    </v-btn>
    <dynamic-data-table
      v-if="section.type === 'multi-input'"
      :table-headers="getSectionHeaders(section)"
      :table-records="tableItems"
      table-title="Table"
    >
    </dynamic-data-table>
    <v-btn
      v-if="node.job.status === 'assigned'"
      :disabled="isLoading"
      :loading="isLoading"
      block
      color="primary"
      cols="4"
      @click="submitSection()"
    >
      {{ !isLastSection ? 'Next' : 'Submit' }}
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { getFilteredRecords } from '@/services/api/record.api';
import DynamicDataTable from '@/components/DynamicDataTable';
import { getTables } from '@/services/api/table.api';
import moment from 'moment';

export default {
  name: 'FormSection',
  components: { DynamicDataTable },
  props: {
    section: {
      type: Object,
      default: () => {
        return {};
      },
    },
    session: {
      type: String,
      required: false,
      default: undefined,
    },
    isLastSection: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tableItems: [],
    inputItem: {},
    formRules: {
      required: (value) => !!value || 'Required.',
      inRange(min, max, value) {
        if (min !== undefined && max !== undefined) {
          min = parseInt(min);
          max = parseInt(max);
          return (
            (value >= min && value <= max) ||
            `Number should be between ${min} and ${max}`
          );
        } else {
          return true;
        }
      },
    },
  }),
  computed: {
    ...mapGetters(['node', 'project', 'selectedNode']),
    getDocumentColumns() {
      let documentFields = [];
      this.tableItems.forEach((item) => {
        return Object.keys(item).forEach((key) => {
          if (typeof item[key] === 'object') {
            documentFields.push(key);
          }
        });
      });

      return documentFields;
    },

    workflowJobCompleted() {
      return this.selectedNode.job.status === 'completed';
    },
  },
  mounted() {
    if (this.node.job.status === 'completed') {
      this.getSessionResult();
    } else {
      setTimeout(() => {
        this.section.fields.forEach((field) => {
          if (field.default !== undefined) {
            Vue.set(this.inputItem, field.column, field.default);
          }
        });
      }, 500);
    }
  },
  methods: {
    getSessionResult() {
      getTables(this.project.id).then((tables) => {
        const table = tables.find((table) => table.name === this.section.table);
        if (table) {
          const filter = {
            session: this.session,
            itemsPerPage: 0,
            page: 1,
          };
          getFilteredRecords(
            table.project,
            table.id,
            filter,
            this.workflowJobCompleted
              ? moment.utc(this.selectedNode.job.updated_at).unix()
              : null
          ).then((response) => {
            if (this.section.type === 'single-input') {
              let result = response[0];
              this.section.fields.forEach((field) => {
                let value = result[field.column];
                Vue.set(this.inputItem, field.column, value);
              });
            } else if (this.section.type === 'multi-input') {
              this.tableItems = response;
            }
          });
        }
      });
    },
    getDocumentCellValue(document) {
      if (document !== undefined && document !== null) {
        return `${document.name}`;
      }
      return '';
    },
    getSectionHeaders(section) {
      let tableHeaders = [];
      section.fields.forEach((field) => {
        tableHeaders.push({
          text: field.label,
          value: field.column,
        });
      });
      return tableHeaders;
    },
    addInputToTable() {
      if (this.$refs.form.validate()) {
        let dataObject = Object.assign({}, this.inputItem);
        dataObject = this.cleanObject(dataObject);
        this.tableItems.push(dataObject);

        this.inputItem = Object.assign({}, {});
        this.$refs.form.reset();
      }
    },
    submitSection() {
      if (this.section.type === 'single-input') {
        if (this.$refs.form.validate()) {
          this.$emit('submit-section', {
            section: this.section,
            data: this.inputItem,
          });
        }
      } else if (this.section.type === 'multi-input') {
        if (this.tableItems.length !== 0) {
          this.$emit('submit-section', {
            section: this.section,
            data: this.tableItems,
          });
        } else {
          this.$store.commit('showNotification', {
            content: `Add at least 1 row to table`,
            color: 'error',
          });
        }
      }
    },
    cleanObject(object) {
      Object.keys(object).forEach((key) => {
        if (object[key] === null || object[key] === undefined) {
          delete object[key];
        }
      });
      return object;
    },
  },
};
</script>

<style lang="scss" scoped>
.inputfield {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
