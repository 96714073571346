<template>
  <div v-if="selectedProjectWorkflow" class="workflow-session-container">
    <div
      class="ant-header radius-0 ant-border-bottom pos-rel d-flex align-center"
    >
      <v-btn
        :ripple="false"
        class="elevation-0 mr-2"
        color="transparent"
        small
        @click="closeDiagramContainer"
      >
        <v-icon left> mdi-chevron-left</v-icon>
        sessions
      </v-btn>
      <div class="session-workflow">
        Workflow: {{ selectedProjectWorkflow.name }}
      </div>
      <v-spacer />
      <v-combobox
        v-model="session"
        :items="sessions"
        class="mr-5"
        dense
        hide-details
        item-text="name"
        item-value="id"
        label="session"
        style="max-width: 250px"
        @change="sessionChange"
      />
    </div>
    <div class="flex-grow-1 d-flex">
      <transition name="fade">
        <div v-if="sessionFlowStatus === 'success'" id="diagram">
          <svg id="svg-container" :width="svgWidth + 'px'" height="100%">
            <defs>
              <marker
                id="arrowhead"
                :fill="$vuetify.theme.themes.light.primary"
                markerHeight="7"
                markerWidth="10"
                orient="auto"
                refX="5"
                refY="3.5"
              >
                <polygon points="0 0, 10 3.5, 0 7" />
              </marker>
            </defs>
          </svg>
          <div class="diagram-container">
            <div
              v-for="column of uniqueColumns"
              :key="column"
              class="diagram-column"
            >
              <diagram-card
                v-for="node in nodesInColumn(column)"
                :id="node.id"
                :key="node.id"
                :node="node"
                @drawLine="drawSvg"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-center align-center fill-height flex-grow-1"
        >
          <ant-loading />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import DiagramCard from './DiagramCard';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'WorkflowSession',
  components: { AntLoading, DiagramCard },
  data: () => {
    return {
      nodes: [],
      svgWidth: 0,
      session: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'projectWorkflows',
      'selectedProjectWorkflow',
      'sessions',
      'sessionsStatus',
      'sessionFlow',
      'sessionFlowStatus',
      'selectedSession',
      'project',
    ]),

    uniqueColumns() {
      return [...new Set(this.sessionFlow.map((o) => o.column))];
    },
  },
  watch: {
    selectedSession: {
      deep: true,
      handler(value) {
        this.loadSessionFlow();
      },
    },
  },
  mounted() {
    if (this.selectedProjectWorkflow === undefined) {
      this.$store.dispatch('setProjectWorkflow', this.$route.params.workflowId);
    }
    this.$store
      .dispatch('fetchProjectWorkflowSessions', {
        projectId: this.project.id,
        projectWorkflowId: this.$route.params.workflowId,
      })
      .then((sessions) => {
        this.session = sessions.find(
          (session) => session.id === this.$route.params.sessionId
        );
      });

    this.loadSessionFlow();
  },
  methods: {
    sessionChange(session) {
      this.$store.dispatch('setSession', session.id);
      this.$router.push({
        name: 'project-workflow-session',
        params: {
          workflowId: this.$route.params.workflowId,
          sessionId: session.id,
        },
      });
    },
    loadSessionFlow() {
      this.$emit('setSession', this.selectedSession);
      this.$store
        .dispatch('fetchSessionFLow', {
          projectId: this.project.id,
          sessionId: this.$route.params.sessionId,
        })
        .then(() => {
          if (this.$route.params.nodeId !== undefined) {
            this.$store.dispatch(
              'setNode',
              this.sessionFlow.find(
                (node) => node.id === this.$route.params.nodeId
              )
            );
          }
        });
    },
    nodesInColumn(column) {
      return this.sessionFlow
        .filter((node) => node.column === column)
        .sort((item1, item2) => (item1.row > item2.row ? 1 : -1));
    },
    drawSvg(config) {
      let svgContainer = document.getElementById('svg-container');
      this.svgWidth = document.getElementById('diagram').scrollWidth;

      if (config.y1 === config.y2) {
        let line = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'line'
        );
        line.id = config.id;
        line.classList.add('build-links');
        line.setAttribute('x1', config.x1);
        line.setAttribute('y1', config.y1);
        line.setAttribute('x2', config.x2);
        line.setAttribute('y2', config.y2);
        line.setAttribute(
          'stroke',
          this.$vuetify.theme.themes.light.primary.toString()
        );
        line.setAttribute(
          'fill',
          this.$vuetify.theme.themes.light.primary.toString()
        );
        line.setAttribute('stroke-width', 2);
        line.setAttribute('marker-end', 'url(#arrowhead)');
        svgContainer.appendChild(line);
      } else {
        let path = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'path'
        );
        path.id = config.id;
        path.classList.add('build-links');
        path.setAttribute(
          'd',
          `M ${config.x1} ${config.y1} H ${config.x2 - 25} V ${config.y2} H ${
            config.x2
          }`
        );
        path.setAttribute(
          'stroke',
          this.$vuetify.theme.themes.light.primary.toString()
        );
        path.setAttribute('fill', 'transparent');
        path.setAttribute('stroke-width', 2);
        path.setAttribute('marker-end', 'url(#arrowhead)');
        svgContainer.appendChild(path);
      }
    },
    closeDiagramContainer() {
      this.$store.dispatch('resetSession');
      this.$router.push({
        name: 'project-workflow-sessions',
        params: {
          workflowId: this.$route.params.workflowId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.workflow-session-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;

  .session-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.75);
    border-top: solid 3px var(--v-primary-base);

    .session-select {
      margin-right: 60px;
    }
  }
}

.diagram-container {
  width: 100%;
  height: 100%;
  position: relative;
}

::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
}

#diagram {
  position: relative;
  width: calc(100vw - 40px);
  overflow: auto;
  padding: 20px;
  flex: 1;

  .diagram-container {
    z-index: 1;
    position: relative;
    display: flex;
    column-gap: 60px;
    flex-direction: row;

    .diagram-column {
      display: flex;
      flex-direction: column;
      row-gap: 60px;
    }
  }

  #svg-container {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .diagram-line {
    stroke-width: 2px;
    stroke: rgb(0, 0, 0);
  }
}
</style>
