<template>
  <v-dialog
    v-model="jobDialog"
    max-width="500px"
    @click:outside="closeAssignJobDialog"
  >
    <v-card>
      <v-card-title>
        <span class="headline"> Assign user to workflow job </span>
      </v-card-title>
      <v-form ref="form" @submit.prevent>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="6">
                <v-combobox
                  v-model="assignedUser"
                  :items="usersWithJob"
                  clearable
                  item-text="name"
                  item-value="id"
                  label="user"
                  solo
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>

      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="closeAssignJobDialog"> Cancel</v-btn>
        <v-btn color="primary" @click="assignUserToJob"> Assign user</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getUsersWithJobRole } from '@/services/api/jobs.api';

export default {
  name: 'AssignJobDialog',
  props: {
    jobDialog: {
      type: Boolean,
      required: true,
    },
    jobItem: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    assignedUser: undefined,
    usersWithJob: [],
  }),
  computed: {
    ...mapGetters(['project']),
  },
  watch: {
    jobItem: function (newVal, oldVal) {
      this.getUsersWithJobRole();
    },
  },
  mounted() {
    this.getUsersWithJobRole();
  },
  methods: {
    getUsersWithJobRole() {
      if (this.jobItem.id !== undefined) {
        getUsersWithJobRole(this.project.id, this.jobItem.id).then((users) => {
          this.usersWithJob = users;
        });
      }
    },
    assignUserToJob() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch('assignUserToJob', {
            projectId: this.project.id,
            jobId: this.jobItem.id,
            body: {
              user: this.assignedUser.id,
            },
          })
          .then((response) => {
            this.closeAssignJobDialog();
          });
      }
    },
    closeAssignJobDialog() {
      this.assignedUser = undefined;
      this.$emit('closeAssignJob');
    },
  },
};
</script>

<style scoped></style>
