<template>
  <div id="resizeContainer" ref="resizeContainer" class="node-container">
    <div
      v-if="nodeStatus === 'loading'"
      class="d-flex justify-center align-center fill-height"
    >
      <ant-loading />
    </div>

    <div
      v-if="nodeStatus === 'success'"
      class="node-navigation-hover-container"
    >
      <div class="ant-glass-background node-navigation-container">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <div
              class="node-navigation-btn"
              v-bind="attrs"
              @click="closeNode"
              v-on="on"
            >
              <v-icon> mdi-chevron-left</v-icon>
            </div>
          </template>
          <span>Back to session</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <div
              class="node-navigation-btn"
              v-bind="attrs"
              @click="openTaskDetail"
              v-on="on"
            >
              <v-icon> mdi-clipboard</v-icon>
            </div>
          </template>
          <span>Open task</span>
        </v-tooltip>
        <v-tooltip
          v-if="isExecutableNode && isUserAllowedToFinishJobManually()"
          bottom
        >
          <template #activator="{ on, attrs }">
            <div
              class="node-navigation-btn"
              v-bind="attrs"
              @click="finishJobForUser"
              v-on="on"
            >
              <v-icon> mdi-clipboard-check</v-icon>
            </div>
          </template>
          <span>Finish Task</span>
        </v-tooltip>
      </div>
    </div>
    <div
      v-if="nodeStatus === 'success' && isExecutableNode"
      id="panel-container"
      class="panels-container"
      @mousedown="startResize"
      @mousemove="dragMouse"
      @mouseup="stopResize"
    >
      <div id="left-container" class="vertical-container ant-border-right">
        <WorkflowNodePanel
          v-if="nodeConfig.panels.left.top.active"
          id="top-left-panel"
          ref="top-left-panel"
          :class="{ 'single-panel': activePanelCount === 1 }"
          :position="{ horizontal: 'left', vertical: 'top' }"
          class="ant-panel--white panel-container ant-border-bottom"
          @finish-job="finishJobForUser"
        />
        <WorkflowNodePanel
          v-if="nodeConfig.panels.left.bottom.active"
          id="bottom-left-panel"
          ref="bottom-left-panel"
          :class="{ 'single-panel': activePanelCount === 1 }"
          :position="{ horizontal: 'left', vertical: 'bottom' }"
          class="ant-panel--white panel-container"
          @finish-job="finishJobForUser"
        />
      </div>
      <div id="right-container" class="vertical-container flex-1">
        <WorkflowNodePanel
          v-if="nodeConfig.panels.right.top.active"
          id="top-right-panel"
          ref="top-right-panel"
          :class="{ 'single-panel': activePanelCount === 1 }"
          :position="{ horizontal: 'right', vertical: 'top' }"
          class="ant-panel--white panel-container ant-border-bottom"
          @finish-job="finishJobForUser"
        />
        <WorkflowNodePanel
          v-if="nodeConfig.panels.right.bottom.active"
          id="bottom-right-panel"
          ref="bottom-right-panel"
          :class="{ 'single-panel': activePanelCount === 1 }"
          :position="{ horizontal: 'right', vertical: 'bottom' }"
          class="ant-panel--white panel-container"
          @finish-job="finishJobForUser"
        />
      </div>
      <v-btn
        v-if="activePanelCount > 1"
        id="resizeBtn"
        ref="resizeBtn"
        class="resize-btn"
        color="primary"
        dark
        fab
        small
      >
        <v-icon dark> mdi-drag-variant</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { finishJob } from '@/services/api/jobs.api';
import AntLoading from '@/components/AntLoading';

export default {
  name: 'WorkflowNode',
  components: {
    AntLoading,
    WorkflowNodePanel: () =>
      import(
        /* webpackChunkName: "workflows" */ '@/components/Project/Workflows/WorkflowNodePanel'
      ),
  },
  data: () => {
    return {
      resize: {
        active: false,
        initialX: 0,
        initialY: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedNode',
      'nodeStatus',
      'project',
      'node',
      'nodeConfig',
      'nodeStatus',
      'isExecutableNode',
    ]),
    activePanelCount() {
      let count = 0;
      // left top panel
      if (this.nodeConfig.panels.left.top.active) {
        count++;
      }

      // left bottom panel
      if (this.nodeConfig.panels.left.bottom.active) {
        count++;
      }

      // right top panel
      if (this.nodeConfig.panels.right.top.active) {
        count++;
      }

      // right bottom panel
      if (this.nodeConfig.panels.right.bottom.active) {
        count++;
      }

      return count;
    },
  },
  watch: {
    selectedNode() {
      if (this.selectedNode !== undefined) {
        this.$store
          .dispatch('fetchNodeConfig', {
            projectId: this.project.id,
            jobId: this.selectedNode.job.id,
          })
          .then(() => {
            if (!this.isExecutableNode) {
              this.openTaskDetail();
            }
            setTimeout(() => {
              this.resizePanels();
            }, 500);
          });
      }
    },
  },
  mounted() {
    this.$store
      .dispatch('fetchSessionFLow', {
        projectId: this.project.id,
        sessionId: this.$route.params.sessionId,
      })
      .then((nodes) => {
        this.$store.dispatch(
          'setNode',
          nodes.find((node) => node.id === this.$route.params.nodeId)
        );
      });
  },
  methods: {
    openTaskDetail() {
      this.$router.push({
        name: 'tasks-detail',
        params: { taskId: this.node.job.task.id },
      });
    },
    isUserAllowedToFinishJobManually() {
      if (this.node.job.status === 'completed') {
        return false;
      }
      // left top panel
      if (this.nodeConfig.panels.left.top.active) {
        if (this.nodeConfig.panels.left.top.type === 'form') {
          return false;
        }
      }

      // left bottom panel
      if (this.nodeConfig.panels.left.bottom.active) {
        if (this.nodeConfig.panels.left.bottom.type === 'form') {
          return false;
        }
      }

      // right top panel
      if (this.nodeConfig.panels.right.top.active) {
        if (this.nodeConfig.panels.right.top.type === 'form') {
          return false;
        }
      }

      // right bottom panel
      if (this.nodeConfig.panels.right.bottom.active) {
        if (this.nodeConfig.panels.right.bottom.type === 'form') {
          return false;
        }
      }

      return true;
    },
    startResize(e) {
      this.resize.initialY = e.clientY;
      this.resize.initialX = e.clientX;
      this.resize.active = true;
    },
    dragMouse(e) {
      if (this.resize.active) {
        let el = document.getElementById('resizeBtn');

        let top = el.offsetTop - (this.resize.initialY - e.clientY);
        if (top <= 0) {
          top = 3;
        }

        let left = el.offsetLeft - (this.resize.initialX - e.clientX);
        if (left <= 0) {
          left = 3;
        }

        el.style.top = top + 'px';
        el.style.left = left + 'px';
        this.resize.initialY = e.clientY;
        this.resize.initialX = e.clientX;

        this.resizePanels();
      }
    },
    resizePanels() {
      let el = document.getElementById('resizeBtn');

      // get positions

      let leftContainer = document.getElementById('left-container');
      let leftTopView = document.getElementById('top-left-panel');
      let leftBottomView = document.getElementById('bottom-left-panel');

      let rightContainer = document.getElementById('right-container');
      let rightTopView = document.getElementById('top-right-panel');
      let rightBottomView = document.getElementById('bottom-right-panel');

      // resize width
      if (el !== null) {
        leftContainer.style.width = el.offsetLeft + 20 + 'px';
      }

      // calculate left panels heights
      if (leftTopView && leftBottomView) {
        leftTopView.style.height = el.offsetTop + 20 + 'px';
        leftBottomView.style.height =
          leftContainer.offsetHeight - leftTopView.offsetHeight + 'px';
      } else {
        if (leftTopView) {
          leftTopView.style.height = '100%';
        } else if (leftBottomView) {
          leftBottomView.style.height = '100%';
        } else {
          leftContainer.style.width = '0';
          rightContainer.style.width = '100%';
        }
      }

      // calculate right panels heights
      if (rightTopView && rightBottomView) {
        rightTopView.style.height = el.offsetTop + 20 + 'px';
        rightBottomView.style.height =
          leftContainer.offsetHeight - rightTopView.offsetHeight + 'px';
      } else {
        if (rightTopView) {
          rightTopView.style.height = '100%';
        } else if (rightBottomView) {
          rightBottomView.style.height = '100%';
        } else {
          leftContainer.style.width = '100%';
          rightContainer.style.width = '0';
        }
      }
    },
    stopResize(e) {
      this.resizePanels();
      this.resize.active = false;
    },

    finishJobForUser() {
      setTimeout(() => {
        finishJob(this.project.id, this.node.job.id).then(() => {
          this.$store.commit('showNotification', {
            content: 'Successfully Finished Job',
            color: 'success',
          });
          this.closeNode();
        });
      }, 500);
    },
    closeNode() {
      this.$store.dispatch('resetNode');
      this.$router.push({
        name: 'project-workflow-session',
        params: {
          workflowId: this.$route.params.workflowId,
          sessionId: this.$route.params.sessionId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.node-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  .node-navigation-hover-container {
    margin: 0 auto;
    padding-bottom: 15px;
    padding-left: 100px;
    padding-right: 100px;
    transform: translateY(-50px);
    transition: 200ms;
    position: absolute;
    z-index: 100;
    top: 0;
    left: calc(50% - 226px);

    &:hover {
      transform: translateY(0);
      padding-top: 20px;
      padding-bottom: 10px;
      padding-left: 100px;
      padding-right: 100px;

      .node-navigation-container {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }

    .node-navigation-container {
      display: flex;
      justify-content: center;

      .node-navigation-btn {
        display: flex;
        flex-direction: column;
        padding: 10px 30px;
        cursor: pointer;
        transition: 200ms;

        &:hover {
          background-color: white;

          .v-icon {
            color: var(--v-primary-base);
          }
        }
      }
    }
  }

  .panels-container {
    position: relative;
    display: flex;
    flex: 1;
    min-height: 0;

    .resize-btn {
      position: absolute;
      left: calc(50% - 20px);
      top: calc(50% - 20px);
      z-index: 150;
    }

    .vertical-container {
      width: 50%;
      max-height: 100%;
      display: flex;
      flex-direction: column;

      .panel-container {
        &.single-panel {
          flex: 1;
          display: flex;
        }
      }
    }
  }
}
</style>
