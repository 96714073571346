<template>
  <div style="width: 100%; overflow: scroll">
    <v-form lazy-validation>
      <v-tabs v-model="tab" grow>
        <v-tab
          v-for="(section, index) in formConfig.sections"
          :key="section.id"
          :disabled="isSectionDisabled(index)"
          @click="openTab(index)"
        >
          <div class="mr-2">
            {{ index + 1 }}
          </div>
          {{ section.name }}
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(section, index) in formConfig.sections"
            :key="section.id"
          >
            <form-section
              :is-last-section="index === formConfig.sections.length - 1"
              :is-loading="isSubmitting"
              :section="section"
              :session="session"
              @submit-section="submitActiveSection"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FileHandlerService from '@/services/file-handler';
import { createRecord } from '@/services/api/record.api';
import { getTables } from '@/services/api/table.api';
import { getColumns } from '@/services/api/column.api';
import DataHelper from '@/services/data-helper';

export default {
  name: 'FormPanel',
  components: {
    FormSection: () =>
      import(
        /* webpackChunkName: "workflows" */ '@/components/Project/Workflows/Panels/FormSection'
      ),
  },
  props: {
    formConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    session: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    tab: null,
    inputSections: [],
    isSubmitting: false,
  }),
  computed: {
    ...mapGetters(['project', 'node']),
  },
  mounted() {
    this.formConfig.sections.forEach((section, index) => {
      let sectionsObject = {};
      sectionsObject.name = section.name;
      sectionsObject.table = section.table;
      sectionsObject.type = section.type;
      sectionsObject.disabled = true;
      sectionsObject.completed = false;
      sectionsObject.data = {};
      this.inputSections[index] = sectionsObject;
    });
    this.inputSections[0].disabled = false;
  },
  methods: {
    openTab(index) {
      if (this.inputSections[index].type === 'multi-input') {
        this.tableItems = this.inputSections[index].multiData;
      }
    },
    isSectionDisabled(sectionIndex) {
      if (this.node.job.status === 'completed') {
        return false;
      } else {
        if (this.inputSections[sectionIndex] !== undefined) {
          return this.inputSections[sectionIndex].disabled;
        }
      }
    },
    submitActiveSection({ section, data }) {
      const index = this.formConfig.sections.findIndex(
        (item) => item === section
      );

      // save data and complete
      this.inputSections[index].data = data;
      this.inputSections[index].completed = true;

      // check if section is the last one
      if (index !== this.formConfig.sections.length - 1) {
        // pending next section
        this.inputSections[index + 1].disabled = false;

        if (this.inputSections[index].completed) {
          this.tab = index + 1;
        }
      } else {
        this.submitSections();
      }
    },
    formatSections() {
      let sections = [];
      let singleInputSections = this.inputSections.filter(
        (section) => section.type === 'single-input'
      );
      let multiInputSections = this.inputSections.filter(
        (section) => section.type === 'multi-input'
      );

      for (let i = 0; i < singleInputSections.length; i++) {
        // check if section data is already combined
        if (
          sections.filter(
            (section) => section.table === singleInputSections[i].table
          ).length === 0
        ) {
          let sameTableSections = singleInputSections.filter(
            (section) => section.table === singleInputSections[i].table
          );
          let dataObject = {};
          sameTableSections.forEach((section) => {
            dataObject = Object.assign({}, dataObject, section.data);
          });

          let section = singleInputSections[i];
          section.data = dataObject;
          sections.push(section);
        }
      }
      return [...sections, ...multiInputSections];
    },
    async submitSections() {
      this.isSubmitting = true;
      let errorsThrown = 0;
      let sections = this.formatSections();

      const tables = await getTables(this.project.id);
      if (tables.length > 0) {
        // last section submitted
        try {
          for (let i = 0; i < sections.length; i++) {
            let inputSection = sections[i];

            // find correct table
            const table = tables.find(
              (table) => table.name === inputSection.table
            );

            if (table) {
              const columns = await getColumns(this.project.id, table.id);
              // data input type
              if (inputSection.type === 'single-input') {
                // create body
                const body = await this.createRecordBody(
                  inputSection.data,
                  table.id,
                  columns
                );

                // create record
                await createRecord(body).catch((error) => {
                  errorsThrown++;
                  this.$store.commit('showNotification', {
                    content: error.message,
                    color: 'error',
                  });
                });
              } else if (inputSection.type === 'multi-input') {
                // recursively add records
                for (const record of inputSection.data) {
                  // create body
                  const body = await this.createRecordBody(
                    record,
                    table.id,
                    columns
                  );

                  // create record
                  await createRecord(body).catch((error) => {
                    errorsThrown++;
                    this.$store.commit('showNotification', {
                      content: error.message,
                      color: 'error',
                    });
                  });
                }
              }
            } else {
              // incorrect table name configured
              this.$store.commit('showNotification', {
                content: `Table not found in project with name: ${inputSection.table}`,
                color: 'error',
              });
              errorsThrown++;
              break;
            }
          }
          if (errorsThrown === 0) {
            this.$emit('finished-form');
          } else {
            this.isSubmitting = false;
          }
        } catch (e) {
          this.$store.commit('showNotification', {
            content: e.message,
            color: 'error',
          });
          this.isSubmitting = false;
        }
      } else {
        // incorrect table name configured
        this.$store.commit('showNotification', {
          content: `Project tables not found`,
          color: 'error',
        });
      }
      this.isSubmitting = false;
    },
    async createRecordBody(data, tableId, columns) {
      let body = {
        project: {
          id: this.project.id,
        },
        table: {
          id: tableId,
        },
        record: {},
      };
      if (this.session) {
        body.session = {
          id: this.session,
        };
      }

      for (const column of columns) {
        if (column.type === 'document') {
          if (data[column.name] && data[column.name].id === undefined) {
            await FileHandlerService.handleFile(data[column.name]).then(
              (value) => {
                body.record[column.name] = value;
              }
            );
          } else {
            body.record[column.name] = null;
          }
        } else {
          body.record[column.name] = DataHelper.parseData(
            column.type,
            data[column.name],
            column.required
          );
        }
      }
      return body;
    },
  },
};
</script>

<style scoped></style>
