<template>
  <div class="project-workflows">
    <div
      class="ant-header radius-0 ant-border-bottom pos-rel d-flex align-center mb-0"
    >
      <div class="flex-1 d-flex justify-start">
        <v-btn
          class="elevation-0"
          color="transparent"
          small
          @click="navigateToProject"
        >
          <v-icon left> mdi-chevron-left</v-icon>
          project
        </v-btn>
      </div>
      <div class="flex-1 d-flex justify-center">
        <span class="flex-1">Project Workflows</span>
      </div>

      <div class="flex-1 d-flex justify-end">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              :to="{ name: 'WorkflowManagement' }"
              color="primary"
              style="margin-right: 10px"
              v-bind="attrs"
              x-small
              v-on="on"
            >
              Workflow builder
            </v-btn>
          </template>
          <span>Navigate to workflow builder. You will leave the project</span>
        </v-tooltip>

        <v-dialog
          key="add-project-workflow"
          v-model="projectWorkflowDialog"
          max-width="500px"
          @click:outside="projectWorkflowDialog = false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :disabled="
                $can('create', 'project-workflow')
                  ? false
                  : !projectWorkflowPermissions.import
              "
              color="primary"
              v-bind="attrs"
              x-small
              @click="openProjectWorkflowDialog"
              v-on="on"
            >
              New Workflow
            </v-btn>
          </template>

          <v-card>
            <v-form ref="form" @submit.prevent>
              <v-tabs v-model="importWorkflowTab" color="primary" grow>
                <v-tab>Collection Workflows</v-tab>
                <v-tab>My Workflows</v-tab>
              </v-tabs>

              <v-tabs-items v-model="importWorkflowTab">
                <v-tab-item>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12" sm="6">
                          <v-text-field
                            v-model="projectWorkflowItem.name"
                            :rules="[rules.required, rules.maxLength]"
                            counter="250"
                            label="name"
                            maxlength="250"
                          />
                        </v-col>
                        <v-col cols="12" md="12" sm="6">
                          <v-select
                            v-model="projectWorkflowItem.workflow"
                            :items="licenseWorkflows"
                            :rules="[rules.required]"
                            item-text="name"
                            item-value="id"
                            label="Select workflow"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="12" sm="6">
                          <v-text-field
                            v-model="projectWorkflowItem.name"
                            :rules="[rules.required, rules.maxLength]"
                            counter="250"
                            label="name"
                            maxlength="250"
                          />
                        </v-col>
                        <v-col cols="12" md="12" sm="6">
                          <v-select
                            v-model="projectWorkflowItem.workflow"
                            :items="userWorkflows"
                            :rules="[rules.required]"
                            item-text="name"
                            item-value="id"
                            label="Select workflow"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-tab-item>
              </v-tabs-items>
              <v-card-actions>
                <v-spacer />
                <v-btn color="error" text @click="closeProjectWorkflowDialog">
                  Cancel
                </v-btn>
                <v-btn color="primary" @click="addWorkflowToProject">
                  Create
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div class="project-workflow-cards-container pa-5">
      <div
        v-for="projectWorkflow in projectWorkflows"
        :key="projectWorkflow.id"
        class="ant-glass-background ma-2 project-workflow-card"
        @click="openWorkflowSessions(projectWorkflow)"
      >
        <div class="workflow-card-header">
          {{ projectWorkflow.name }}
        </div>
        <div class="workflow-card-description">
          {{ projectWorkflow.workflow.description }}
        </div>
        <v-btn
          v-if="
            projectWorkflowPermissions.configure ||
            $can('update', 'project-workflow')
          "
          class="workflow-settings-btn ant-glass-background"
          icon
          @click.stop="configureWorkflow(projectWorkflow)"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WorkflowOverview',
  data: () => {
    return {
      projectWorkflowDialog: false,
      projectWorkflowItem: {},
      importWorkflowTab: 0,
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          (value && value.length < 250) || 'Max 250 characters',
      },
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'projectWorkflows',
      'licenseWorkflows',
      'userWorkflows',
      'projectWorkflowPermissions',
    ]),
  },
  methods: {
    openWorkflowSessions(projectWorkflow) {
      this.$router.push({
        name: 'project-workflow-sessions',
        params: { workflowId: projectWorkflow.id },
      });
    },
    configureWorkflow(projectWorkflow) {
      this.$router.push({
        name: 'project-workflow-settings',
        params: { workflowId: projectWorkflow.id },
      });
    },
    addWorkflowToProject() {
      if (this.$refs.form.validate()) {
        // create body
        let body = {
          workflow: {
            id: this.projectWorkflowItem.workflow,
          },
          name: this.projectWorkflowItem.name,
        };

        // add workflow to project
        this.$store
          .dispatch('addWorkflowToProject', {
            projectId: this.project.id,
            body,
          })
          .then((response) => {
            this.closeProjectWorkflowDialog();
          });
      }
    },
    openProjectWorkflowDialog() {
      this.$store.dispatch('fetchWorkflowsInLicense', {
        projectId: this.project.id,
      });
      this.$store.dispatch('fetchUserWorkflows');

      this.projectWorkflowDialog = true;
    },
    closeProjectWorkflowDialog() {
      this.projectWorkflowItem = Object.assign({}, {});
      this.projectWorkflowDialog = false;
      this.$refs.form.reset();
    },
    navigateToProject() {
      this.$router.push({ name: 'project-overview' });
    },
  },
};
</script>

<style lang="scss" scoped>
.project-workflows {
  flex: 1;
  display: flex;
  flex-direction: column;

  .project-workflow-cards-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
    overflow-y: scroll;

    .project-workflow-card {
      padding: 10px 12px;
      margin: 0 10px;
      border-left: solid 3px var(--v-primary-base);
      box-sizing: border-box;
      overflow: hidden;
      min-height: 100px;
      transition: box-shadow 200ms ease-out;
      cursor: pointer;

      &:hover {
        box-shadow:
          rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

        .workflow-settings-btn {
          opacity: 1;
        }
      }

      &.active {
        box-shadow:
          rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }

      .workflow-settings-btn {
        position: absolute;
        right: 7px;
        top: 7px;
        opacity: 0;
        transition: 200ms;
      }

      .workflow-card-header {
        font-size: 16px;
        overflow-wrap: break-word;
      }

      .workflow-card-description {
        color: #525252;
        font-size: 12px;
      }
    }
  }
}
</style>
