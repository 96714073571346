<template>
  <div :class="{ fullscreen: fullscreen }" class="workflows-container">
    <transition mode="out-in" name="fade">
      <router-view v-if="projectWorkflowsStatus === 'success'" />
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Workflows',
  data: () => {
    return {
      fullscreen: false,
    };
  },
  computed: {
    ...mapGetters(['project', 'projectWorkflowsStatus']),
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(value) {
        this.fullscreen = value.name === 'project-workflow-session-node';
      },
    },
  },
  mounted() {
    this.$store.dispatch('fetchProjectWorkflows', {
      projectId: this.project.id,
    });
  },
};
</script>

<style lang="scss" scoped>
.workflows-container {
  display: flex;
  min-width: 0;

  &.fullscreen {
    padding: 0;
  }
}
</style>
