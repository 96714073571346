<template>
  <img
    :src="'data:image/' + media.extension + ';base64, ' + media.data"
    alt=""
    class="img-container"
  />
</template>

<script>
export default {
  name: 'MediaPanel',
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.img-container {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>
