<template>
  <dynamic-data-table
    :is-loading="projectWorkflowTeamsStatus === 'loading'"
    :table-headers="teamHeaders"
    :table-records="filteredTeams"
    table-title="Teams"
  >
    <template #item.roles="{ value, rowId, item }">
      <v-chip
        v-for="role in value"
        :key="role.id"
        :color="role.color"
        :text-color="getContrastColor(role.color)"
        class="ma-2"
        close
        @click:close="removeRoleFromTeam(rowId, role)"
      >
        {{ role.name }}
      </v-chip>
    </template>
    <template #item.is_admin="{ value }">
      <v-checkbox :input-value="value" disabled />
    </template>
    <template #table-row-actions="{ item }">
      <v-tooltip
        v-if="
          selectedRole !== null &&
          item.roles.findIndex((x) => x.id === selectedRole.id) === -1
        "
        left
      >
        <template #activator="{ on, attrs }">
          <v-icon
            :disabled="selectedRole === null"
            small
            v-bind="attrs"
            @click="addRoleToTeam(item)"
            v-on="on"
          >
            mdi-account-key
          </v-icon>
        </template>
        <span>Add "{{ selectedRole.name }}" to team</span>
      </v-tooltip>
    </template>
  </dynamic-data-table>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import { mapGetters } from 'vuex';
import ColorHelper from '@/services/task-helper';

export default {
  name: 'WorkflowManagementTeams',
  components: { DynamicDataTable },
  data: () => ({
    teamHeaders: [
      {
        text: 'name',
        value: 'name',
      },
      {
        text: 'roles',
        value: 'roles',
        hasSlot: true,
      },
      {
        text: 'admin',
        value: 'is_admin',
        hasSlot: true,
      },
      { text: 'Actions', value: 'actions', align: 'right', sortable: false },
    ],
  }),
  computed: {
    ...mapGetters([
      'project',
      'projectWorkflowTeams',
      'projectWorkflowTeamsStatus',
      'selectedRole',
      'filteredRole',
    ]),

    filteredTeams() {
      if (this.filteredRole) {
        return this.projectWorkflowTeams.filter(
          (team) =>
            team.roles.findIndex((role) => role.id === this.filteredRole.id) !==
            -1
        );
      }
      return this.projectWorkflowTeams;
    },
  },
  mounted() {
    this.$store.dispatch('fetchProjectWorkflowTeams', {
      projectId: this.project.id,
      workflowId: this.$route.params.workflowId,
    });
  },
  methods: {
    getContrastColor(colorHex) {
      return ColorHelper.getContrastColor(colorHex);
    },
    addRoleToTeam(team) {
      this.$store.dispatch('addRoleToTeam', {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        role: this.selectedRole,
        team,
      });
    },
    removeRoleFromTeam(team, role) {
      this.$store.dispatch('removeRoleFromTeam', {
        projectId: this.project.id,
        workflowId: this.$route.params.workflowId,
        role: role,
        teamId: team,
      });
    },
  },
};
</script>

<style scoped></style>
