<template>
  <v-tooltip
    :bottom="tooltip === 'bottom'"
    :color="getStatusIconMaterialColor"
    :left="tooltip === 'left'"
    :right="tooltip === 'right'"
  >
    <template #activator="{ on, attrs }">
      <v-icon
        :color="getStatusIconColor"
        dark
        v-bind="attrs"
        @click="openTaskDetail()"
        v-on="on"
      >
        {{ getStatusIcon }}
      </v-icon>
    </template>
    <span>{{ status }}</span>
  </v-tooltip>
</template>

<script>
import TaskHelper from '../../services/task-helper';

export default {
  name: 'TaskStatus',
  props: {
    status: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
  },
  computed: {
    getStatusIcon() {
      return TaskHelper.getStatusIcon(this.status);
    },
    getStatusIconColor() {
      return TaskHelper.getStatusIconColor(this.status);
    },
    getStatusIconMaterialColor() {
      return TaskHelper.getStatusIconMaterialColor(this.status);
    },
  },
  methods: {
    openTaskDetail() {
      this.$emit('openTaskDetail');
    },
  },
};
</script>
