import { render, staticRenderFns } from "./WorkflowNodePanel.vue?vue&type=template&id=79534583&scoped=true"
import script from "./WorkflowNodePanel.vue?vue&type=script&lang=js"
export * from "./WorkflowNodePanel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79534583",
  null
  
)

export default component.exports